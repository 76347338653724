.contact-container,
.contact-success-container {
	height: 100%;
	width: 100%;
	color: #ffffff;
	background-color: #333333;
}

.contact-form-container {
	/* height: 100%; */
	padding-top: 50px;
}

.contact-form-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
}

.contact-input-container.half {
	flex: 1 1 50%;
	/* position: relative; */
	max-width: 400px;
}

.contact-input-container.full {
	flex: 1 1 100%;
	/* position: relative; */
	display: flex;
}

.contact-label {
	position: absolute;
	top: -25px;
	font-size: 1.3rem;
	transition: opacity 200ms ease-in-out;
	color: #ff9fda;
	font-family: 'Lato', sans-serif;
}

.contact-label.hide {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.contact-label.show {
	opacity: 1;
	transition: opacity 200ms ease-in-out;
}

.contact-input {
	width: 100%;
	height: 25px;
	padding: 5px;
	padding-bottom: 15px;
	background-color: #333333;
	color: #ffffff;
	border: none;
	border-bottom: 1px solid #ffffff;
	outline: none;
	font-family: 'Lato', sans-serif;
	font-size: 2rem;
}

.contact-input:focus {
	border-color: #ff9fda;
}

textarea.contact-input {
	height: 200px;
	resize: none;
}

.contact-submit {
	width: 125px;
	height: 35px;
	font-size: 1.8rem;
	font-weight: 600;
	border: none;
	font-family: 'Forum', cursive;
	letter-spacing: 0.5px;
	color: #333333;
	background-color: #ffffff;
	cursor: pointer;
	transition: all 300ms ease-in-out;
}

.contact-submit:hover {
	color: #ffffff;
	background-color: #333333;
}

.contact-success-container h3 {
	font-weight: 400;
	font-size: 2rem;
	font-style: oblique;
	text-align: center;
	padding: 75px 75px 0px 75px;
}

@media screen and (max-width: 885px) {
	.contact-form-row {
		margin-bottom: 0;
		flex-wrap: wrap;
	}

	.contact-input-container.half {
		flex: 1 1 100%;
		max-width: 100%;
		margin-bottom: 50px;
	}

	.contact-input-container.full {
		margin-bottom: 50px;
	}

	textarea.contact-input {
		height: 150px;
	}
}

@media screen and (max-height: 850px) {
	.contact-container {
		height: auto;
	}
}
