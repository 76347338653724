.content-area {
	height: calc(100vh - 245px);
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.content-area::-webkit-scrollbar {
	display: none;
}

@media screen and (max-width: 768px) {
	.content-area {
		height: calc(100vh - 190px);
	}
}
