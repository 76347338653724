.about-us-container {
	height: 100%;
	width: 100%;
	color: #ffffff;
	background-color: #333333;
}

.profile-container {
	display: flex;
}

.profile-column {
	flex: 0 1 50%;
}

.profile-column.profile-picture {
	padding: 20px;
	display: flex;
	justify-content: center;
}

.profile-column.profile-picture img {
	max-width: 300px;
	width: 100%;
	height: 100%;
	border: 2px solid #fff;
	-moz-box-shadow: 7px 7px 5px #ccc;
	-webkit-box-shadow: 7px 7px 5px #ccc;
	box-shadow: 7px 7px 5px #ccc;
	-moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
	object-fit: cover;
}

.profile-column.profile-text {
	padding: 40px 20px;
	font-size: 1.8rem;
}

.profile-column.profile-text p {
	margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
	.about-us-container {
		height: auto;
	}

	.profile-container {
		flex-direction: column;
	}

	.profile-column.profile-picture img {
		max-width: 300px;
	}

	/* .profile-column {
		flex: 1 1 100%;
	} */
}
