.top-nav-container {
	width: 100%;
	height: 125px;
	position: sticky;
	top: 0px;
	padding: 10px 0;
	background-color: #333333;
}

.pagewrap.top-nav-desktop {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.pagewrap.top-nav-mobile {
	display: none;
}

.top-nav-column {
	flex: 1 1 calc(50% - 150px);
	display: flex;
	justify-content: space-between;
}

.top-nav-list {
	width: 100%;
	display: flex;
	justify-content: space-around;
	list-style: none;
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.8px;
}

.top-nav-link {
	text-decoration: none;
	color: #ffffff;
}

.top-nav-link:visited,
.top-nav-link:active {
	color: #ffffff;
}

.top-nav-logo {
	flex: 0 0 250px;
}

.top-nav-logo img {
	width: auto;
	max-height: 60px;
}

@media screen and (max-width: 768px) {
	.top-nav-container {
		padding: 0;
		height: 80px;
	}

	.pagewrap.top-nav-desktop {
		display: none;
	}

	.pagewrap.top-nav-mobile {
		display: flex;
	}

	.top-nav-mobile .top-nav-column {
		flex: 1 1 100%;
		align-items: center;
	}

	.top-nav-mobile-icon {
		width: 50px;
		height: 100%;
		background-position: center;
		background-size: 50%;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	.top-nav-mobile-icon.open {
		background-image: url('./menu_open.png');
	}

	.top-nav-mobile-icon.close {
		background-image: url('./menu_close.png');
		height: 50px;
		position: absolute;
		top: 15px;
		left: 15px;
	}

	.top-nav-mobile-icon.close.hidden {
		display: none;
	}

	.top-nav-mobile-icon.close.show {
		display: block;
	}

	.top-nav-mobile-sub-menu {
		background-color: rgba(0, 0, 0, 0.95);
		height: 100vh;
		width: 100%;
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		display: flex;
	}

	.top-nav-mobile-sub-menu.closed {
		left: -800px;
		transition: left 500ms ease-in-out;
	}

	.top-nav-mobile-sub-menu.open {
		left: 0;
		transition: left 500ms ease-in-out;
	}

	.top-nav-mobile-list {
		width: 100%;
		height: 100%;
		padding: 75px;
	}

	.top-nav-mobile-link {
		padding: 25px 10px;
		font-size: 3rem;
		text-align: center;
	}

	.top-nav-mobile-link a {
		padding: 15px;
		color: #ffffff;
		text-decoration: none;
	}
}
