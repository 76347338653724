.pricing-container {
	background-color: #333333;
	color: #ffffff;
}

.package-header {
	font-size: 2.8rem;
	margin: 20px 0;
	text-align: center;
	border-top: 1px solid #ffffff;
	border-bottom: 1px solid #ffffff;
	padding: 10px 0;
	display: inline-block;
	font-weight: 400;
}

.package-additional-info {
	margin: 10px 0;
	text-align: center;
	font-style: oblique;
	font-size: 1.3rem;
	font-weight: 400;
}

.package-container {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.package-info-row {
	border-bottom: 1px solid lightgrey;
	padding: 20px 0;
	display: flex;
	width: 100%;
}

.package-image-container {
	flex: 0 0 225px;
	display: flex;
	align-items: center;
}

.package-image-container img {
	box-shadow: 0 0 10px 3px #ffffff;
}

.package-info-text {
	flex: 1 1 calc(100% - 225px);
	/* border: 1px solid red; */
	display: flex;
	flex-direction: column;
}

.package-info-text {
	align-items: center;
	justify-content: center;
	text-align: center;
}

.package-header-script,
.package-info-title {
	font-family: 'Dancing Script', cursive;
	color: rgb(255, 159, 218);
}

.package-image-container img {
	max-width: 200px;
	max-height: 200px;
}

.package-info-title {
	font-size: 3rem;
	margin-bottom: 10px;
}

.package-info-list {
	font-size: 1.8rem;
}

@media screen and (max-width: 600px) {
	.package-info-row {
		flex-wrap: wrap;
	}

	.package-image-container,
	.package-info-text {
		flex: 1 1 100%;
		justify-content: center;
	}

	.package-image-container {
		margin-bottom: 25px;
	}

	.package-container
		.package-info-row:nth-of-type(2)
		.package-image-container {
		order: 1;
	}

	.package-container .package-info-row:nth-of-type(2) .package-info-text {
		order: 2;
	}

	.package-info-row:nth-of-type(2),
	.package-info-row:nth-of-type(3) {
		margin-top: 15px;
	}
}
