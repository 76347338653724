* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	font-family: 'Forum', cursive;
	color: #333333;
}

body.no-scroll {
	overflow: hidden;
}

ul {
	list-style: none;
}
