.homepage-container {
	height: 100%;
	width: 100%;
	background: center / cover no-repeat url('./background.jpg');
}

.homepage-overlay {
	height: 100%;
	width: 100%;
	/* padding: 0; */
	background-color: rgba(33, 33, 33, 0.85);
	color: #ffffff;
	font-family: 'Dancing Script', cursive;
	padding-top: 50px;
}

.homepage-overlay .title {
	font-size: 5rem;
	/* text-align: center; */
	padding: 20px 0;
	font-weight: 100;
}

.homepage-overlay .title.line-1 {
	text-align: left;
	padding-left: 40px;
}

.homepage-overlay .title.line-2 {
	text-align: center;
}

.homepage-overlay .title.line-3 {
	text-align: right;
	padding-right: 40px;
}

@media screen and (max-width: 600px) {
	.homepage-overlay {
		padding-top: 25px;
	}

	.homepage-overlay .title.line-1,
	.homepage-overlay .title.line-2,
	.homepage-overlay .title.line-3 {
		padding: 20px;
		text-align: center;
	}

	.homepage-overlay .title {
		font-size: 3.5rem;
	}
}
