.gallery-container {
	background-color: #333333;
	color: #ffffff;
}

.gallery-images-container {
	width: 95%;
	margin: 25px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.gallery-image {
	flex: 0 1 400px;
	height: 300px;
	margin: 10px;
	cursor: pointer;
}

.gallery-image img {
	width: 100%;
	height: 100%;
	border: 2px solid white;
}

.gallery-full-image-overlay {
	width: 100vw;
	position: fixed;
	background: black;
	top: 0;
	bottom: 0;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
}

.gallery-full-image {
	position: relative;
	opacity: 1;
	z-index: 101;
	max-width: 85%;
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
}

.gallery-full-image img {
	width: 100%;
	height: 100%;
}

.gallery-nav-button {
	width: 30px;
	height: 30px;
	font-size: 3.5rem;
	color: #ffffff;
	background-color: transparent;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.gallery-nav-button:focus {
	outline: none;
}

.gallery-nav-button.prev {
	left: 25px;
	background-image: url('./previous.png');
	width: 30px;
	height: 38px;
}

.gallery-nav-button.next {
	right: 25px;
	background-image: url('./next.png');
	width: 30px;
	height: 38px;
}

@media only screen and (max-width: 767px) {
	.gallery-full-image {
		padding: 5px;
	}

	.gallery-full-image img {
		width: 100%;
		height: 100%;
	}

	.gallery-nav-button.prev {
		left: 25px;
		background-image: url('./previous_mobile.png');
		width: 15px;
		height: 19px;
	}

	.gallery-nav-button.next {
		right: 25px;
		background-image: url('./next_mobile.png');
		width: 15px;
		height: 19px;
	}
}

@media only screen and (max-width: 600px) {
	.gallery-image {
		height: auto;
	}

	.gallery-image img {
		object-fit: cover;
		width: 100%;
		max-height: 100%;
		height: auto;
	}

	.gallery-full-image {
		max-width: 75%;
	}
}
