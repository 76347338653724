.pagewrap {
	max-width: 1500px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

.pagewrap.homepage,
.pagewrap.about-us,
.pagewrap.contact,
.pagewrap.gallery,
.pagewrap.pricing {
	padding: 40px;
}
