.footer-container {
	background-color: #333333;
}

.pagewrap.footer {
	display: flex;
}

.footer-column {
	flex: 0 1 50%;
	display: flex;
	padding: 30px 5px;
}

.footer-column.socials {
	justify-content: flex-start;
}

.footer-column.contact {
	justify-content: flex-end;
}

.footer-social-nav {
	width: 150px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.footer-contact-nav {
	display: flex;
	width: 300px;
	flex-direction: column;
	font-size: 1.6rem;
}

.social-nav-link a,
.contact-nav-link a {
	color: #ffffff;
	text-decoration: none;
}

.contact-nav-link a {
	font-size: 1.8rem;
}

.footer-social-nav li.social-nav-link {
	width: 50px;
	text-align: center;
	padding: 5px 0;
}

.footer-social-nav li.social-nav-link.instagram:hover {
	background: -webkit-radial-gradient(
			32% 106%,
			circle cover,
			rgb(255, 225, 125) 0%,
			rgb(255, 205, 105) 10%,
			rgb(250, 145, 55) 28%,
			rgb(235, 65, 65) 42%,
			transparent 82%
		),
		-webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190)
					58%);
}

.footer-social-nav li.social-nav-link.facebook:hover {
	background-color: #4267b2;
}

.footer-social-nav li.social-nav-link.twitter:hover {
	background-color: rgb(29, 161, 242);
}

.footer-social-nav li.social-nav-link:hover a {
	color: #ffffff;
}

.social-nav-icon {
	font-size: 2rem;
}

.contact-nav-link {
	text-align: right;
	line-height: 20px;
}

.contact-nav-link p {
	font-style: oblique;
	font-size: 1.2rem;
	color: #ffffff;
}

.contact-nav-link p a {
	font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
	.pagewrap.footer {
		flex-wrap: wrap;
		padding: 5px 0;
	}

	.footer-column.socials,
	.footer-column.contact {
		flex: 1 1 100%;
		justify-content: center;
		padding: 5px;
	}

	.contact-nav-link {
		text-align: center;
	}
}
